@import 'https://dvna932ue33yp.cloudfront.net/website/fonts/gwat/gwat-fonts.css?kqmcdp';
@import './font.css';
@import 'variables.css';
@import 'hamburger.css';
@import './modals.css';
@import './inner-html-styles.css';
@import './toc-inner-html-styles.css';
@import './polyfills.css';

.reviews-customers-dropdown__menu-list
  > div
  > div:nth-child(2)
  .reviews-customers-dropdown__group
  > div {
  column-count: 4;
  border-left: 1px solid #ccc;
}

.popup-enter {
  opacity: 0;
  top: -80px !important;
  bottom: unset !important;
}
.popup-enter-active {
  opacity: 1;
  top: 10px !important;
  transition: opacity 300ms ease-in-out, top 300ms ease-in-out;
  bottom: unset !important;
}
.popup-exit {
  opacity: 1;
  top: 10px !important;
  bottom: unset !important;
}
.popup-exit-active {
  opacity: 0;
  top: -80px !important;
  transition: opacity 300ms ease-in-out, top 300ms ease-in-out;
  bottom: unset !important;
}

@media (min-width: 768px) {
  .popup-enter {
    opacity: 0;
    bottom: -120px !important;
    top: unset !important;
  }
  .popup-enter-active {
    opacity: 1;
    bottom: 32px !important;
    transition: opacity 300ms ease-in-out, bottom 300ms ease-in-out;
    top: unset !important;
  }
  .popup-exit {
    opacity: 1;
    bottom: 32px !important;
    top: unset !important;
  }
  .popup-exit-active {
    opacity: 0;
    bottom: -120px !important;
    transition: opacity 300ms ease-in-out, bottom 300ms ease-in-out;
    top: unset;
  }
}

.page-transition-enter {
  opacity: 0;
}
.page-transition-exit {
  opacity: 1;
}
.page-transition-enter-active {
  opacity: 1;
}
.page-transition-exit-active {
  opacity: 0;
}
.page-transition-enter-active,
.page-transition-exit-active {
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}

.hide-webkit-scrollbar::-webkit-scrollbar {
  display: none;
}

.webkit-custom-scrollbar::-webkit-scrollbar {
  height: 0.6rem;
}
.webkit-custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 1rem;
}
.webkit-custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 1rem;
}
.webkit-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

/* --------Fade-------- */
.lightbox-fade-enter {
  opacity: 0;
}
.lightbox-fade-enter.lightbox-fade-enter-active {
  opacity: 0;
  transition: 500ms linear;
}
.lightbox-fade-enter-done {
  opacity: 1;
}
.lightbox-fade-exit {
  opacity: 1;
}
.lightbox-fade-exit.lightbox-fade-exit-active {
  opacity: 0;
  transition: 500ms linear;
}
.lightbox-fade-exit-done {
  opacity: 0;
}
/* --------Scale-------- */
.lightbox-scale-enter {
  scale: 0;
  transform: scale(0);
}
.lightbox-scale-enter-active {
  scale: 1;
  transform: scale(1);
  transition: scale 300ms, transform 300ms;
}
.lightbox-scale-exit {
  scale: 1;
  transform: scale(1);
}
.lightbox-scale-exit-active {
  transform: scale(0);
  transition: scale 300ms, transform 300ms;
}
/* --------TranslateY-------- */
.filter-translatey-enter {
  transform: translateY(100vh);
}
.filter-translatey-enter.filter-translatey-enter-active {
  transform: translateY(100vh);
}
.filter-translatey-enter-done {
  transform: translateY(0);
  transition: 300ms linear;
}
.filter-translatey-exit {
  transform: translateY(0);
}
.filter-translatey-exit.filter-translatey-exit-active {
  transform: translateY(100vh);
  transition: 300ms linear;
}
.filter-translatey-exit-done {
  transform: translateY(100vh);
}
/* --------TranslateY-------- */
.modal-translatey-enter {
  height: 0;
}
.modal-translatey-enter.modal-translatey-enter-active {
  height: 0;
}
.modal-translatey-enter-done {
  height: calc(100% - 0px);
  transition: 400ms ease;
}
.modal-translatey-exit {
  height: calc(100% - 0px);
  transition: 400ms ease;
}
.modal-translatey-exit.modal-translatey-exit-active {
  height: 0;
}
.modal-translatey-exit-done {
  height: 0;
}
/* --------Promo-------- */
.header-fade-enter {
  opacity: 0;
  transition: 250ms linear;
}
.header-fade-enter.header-fade-enter-active {
  opacity: 0;
  transition: 250ms linear;
}
.header-fade-enter-done {
  opacity: 1;
  transition: 250ms linear;
}
.header-fade-exit {
  opacity: 1;
}
.header-fade-exit.header-fade-exit-active {
  opacity: 0;
  transition: 250ms linear;
}
.header-fade-exit-done {
  opacity: 0;
  display: none;
}
/* -----------ToolTip--------------- */
.tippy-box[data-theme~='light-border'] {
  min-width: 360px;
  border: 1px solid #f4c45c !important;
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%) !important;
  padding: 0.625rem;
}
.tippy-box[data-theme~='light-border'][data-placement^='top']
  > .tippy-arrow:after {
  border-top-color: #f4c45c !important;
}
.tippy-box[data-theme~='light-border'][data-placement^='bottom']
  > .tippy-arrow:after {
  border-bottom-color: #f4c45c !important;
}
@media only screen and (max-width: 640px) {
  .tippy-box[data-theme~='light-border'] {
    padding: 0.625rem 1rem 0.75rem 0.625rem;
  }
}

a:focus-visible {
  border: 0px !important;
}

ul::-webkit-scrollbar {
  display: none;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

p {
  @apply text-base leading-5 text-dark-700;
}

ul li {
  @apply text-dark-700;
}

.nav-menu-fade-in {
  animation: nav-menu-fade-in 0.4s ease-in-out;
}

.content-box-important {
  box-sizing: content-box !important;
}

@keyframes nav-menu-fade-in {
  0% {
    opacity: 0;
    transform: scale(0.97);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.slash-background {
  background: linear-gradient(183.92deg, #2fd2ef, #15a6da);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
}
@media (min-width: 768px) {
  .slash-background {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
  }
}

/* -----------auto-search-scroll------------------ */
.auto-search__menu-list {
  max-height: 16rem;
}
.auto-search__menu-list::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.auto-search__menu-list::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 6px;
}

/* --------------lightbox-scroll---------------------- */
.lightbox-scroll {
  overflow: overlay;
}
.lightbox-scroll::-webkit-scrollbar {
  width: 5px;
}
.lightbox-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}

.jobs-and-location h2 {
  margin-bottom: 1.25rem;
}

/* to move intercom icon up in product-page (mobile mode) so that it does't block our components */
@media (max-width: 1024px) {
  .risen-intercom .intercom-launcher {
    margin-bottom: 80px;
  }
  .intercom-launcher {
    transition: margin 0.3s ease-in-out;
  }
  .risen-intercom .intercom-lightweight-app-launcher {
    margin-bottom: 80px;
  }
  .intercom-lightweight-app-launcher {
    transition: margin 0.3s ease-in-out;
  }
  .risen-intercom .intercom-launcher-frame {
    margin-bottom: 80px;
  }
  .intercom-launcher-frame {
    transition: margin 0.3s ease-in-out;
  }
}
/*--------------product image gradiant on mobile------------------------------------*/
.bg-gradiant-product-image {
  background: linear-gradient(
    180deg,
    #17181a 0%,
    rgba(23, 24, 26, 0) 27.6%,
    rgba(23, 24, 26, 0) 73.44%,
    #17181a 100%
  );
}

.fillBack {
  background-color: #fff;
  width: 0%;
  animation: makeItfadeIn 6s 1s forwards;
}
@keyframes makeItfadeIn {
  100% {
    width: 100%;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.custom-radio::after {
  content: '';
  width: 0.35rem;
  height: 0.35rem;
  background-color: #fff;
  position: absolute;
  border-radius: 100%;
  margin: auto;
  left: 0;
  right: 0;
  top: 35%;
}
/* ----------------Mobile Modal Animation-------------- */
/* --------TranslateY-------- */
.modal-flight-enter {
  transform: translateY(100vh);
}
.modal-flight-enter.modal-flight-enter-active {
  transform: translateY(100vh);
}
.modal-flight-enter-done {
  transform: translateY(0);
  transition: 300ms linear;
}
.modal-flight-exit {
  transform: translateY(0);
}
.modal-flight-exit.modal-flight-exit-active {
  transform: translateY(100vh);
  transition: 300ms linear;
}
.modal-flight-exit-done {
  transform: translateY(100vh);
}

/* --------TranslateY-------- */
.modal-flight-top-enter {
  transform: translateY(-100vh);
}
.modal-flight-top-enter.modal-flight-top-enter-active {
  transform: translateY(-100vh);
}
.modal-flight-top-enter-done {
  transform: translateY(0);
  transition: 300ms linear;
}
.modal-flight-top-exit {
  transform: translateY(0);
}
.modal-flight-top-exit.modal-flight-top-exit-active {
  transform: translateY(-100vh);
  transition: 300ms linear;
}
.modal-flight-top-exit-done {
  transform: translateY(-100vh);
}
/*------------------ButtonLoad---------------------*/
.button-load {
  position: relative;
  transition: all 0.2s;
}

.load-circle::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.load-circle.load-circle_blue::after {
  border-color: #0083a0;
  border-top-color: white;
}

.load-circle-favorite::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 4px solid #ffffff40;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
.load-circle-favorite-mobile::after {
  content: '';
  position: absolute;
  width: 28px;
  height: 28px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 4px solid #ffffff40;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
/*----------------------Toaster----------------------*/
.Toastify__toast {
  padding: 0 !important;
  height: 0 !important;
}
.Toastify__close-button {
  display: none !important;
}
.Toastify__toast-body {
  padding: 0 !important;
}
.Toastify__toast-theme--light {
  height: auto !important;
}
/*--------------product image gradiant on mobile------------------------------------*/
.bg-gradiant-product-image {
  background: linear-gradient(
    180deg,
    #17181a 0%,
    rgba(23, 24, 26, 0) 27.6%,
    rgba(23, 24, 26, 0) 73.44%,
    #17181a 100%
  );
}

.fillBack {
  background-color: #fff;
  width: 0%;
  animation: makeItfadeIn 6s 1s forwards;
}
@keyframes makeItfadeIn {
  100% {
    width: 100%;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.custom-radio::after {
  content: '';
  width: 0.35rem;
  height: 0.35rem;
  background-color: #fff;
  position: absolute;
  border-radius: 100%;
  margin: auto;
  left: 0;
  right: 0;
  top: 35%;
}

/* --------VideoPlayer------------------------ */
.player-dimensions {
  width: 100% !important;
  height: 100% !important;
}

.cld-video-player video {
  object-fit: cover;
}

.desktop-player video {
  object-fit: contain;
}

.mobile-player video {
  object-fit: contain;
}

.cld-video-player {
  width: 100% !important;
  height: 100% !important;
}

.vjs-poster {
  background-size: cover !important;
}
.vjs-control-bar {
  background-color: #0000 !important;
}

.desktop-player .vjs-progress-control {
  position: unset !important;
  height: unset !important;
  padding-top: 2px !important;
}

.desktop-player .vjs-volume-panel {
  width: 2rem !important;
}

.desktop-player .vjs-control-bar {
  margin-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.desktop-player .vjs-play-control {
  background: #ffffff50;
  border-radius: 100%;
}

.cld-video-player-vjs_video_3 .vjs-slider {
  background-color: rgb(255 255 255 / 50%) !important;
}

.cld-video-player-vjs_video_3 .vjs-load-progress div {
  background-color: rgb(255 255 255 / 50%) !important;
}

.cld-video-player-vjs_video_3 .vjs-volume-vertical {
  background-color: #fff0 !important;
}

.cld-video-player-vjs_video_3 .vjs-volume-level {
  background: #fe5754 !important;
}

.cld-video-player-vjs_video_3 .vjs-volume-vertical {
  background-color: #fff0 !important;
}

.cld-video-player-vjs_video_3 .vjs-volume-level {
  background: #fe5754 !important;
}

.mobile-player .vjs-control-bar {
  justify-content: end;
  margin-bottom: 32px;
  padding-right: 16px;
}
.mobile-player .vjs-volume-panel {
  margin-right: 0 !important;
  width: 2rem !important;
}
.mobile-player .vjs-progress-control {
  display: none !important;
}

.mobile-player.vjs-fullscreen .vjs-progress-control {
  display: flex !important;
}
.mobile-player.vjs-fullscreen .vjs-control-bar {
  justify-content: start !important;
  margin-bottom: 16px !important;
  padding-right: 0px !important;
}
.mobile-player.vjs-fullscreen .vjs-progress-control {
  position: unset !important;
  padding: 0 !important;
  height: unset !important;
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  opacity: 1 !important;
}
.itinerary-wrapper a {
  @apply text-teal-900;
}
